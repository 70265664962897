import React, { PureComponent } from "react";
import Iframe from 'react-iframe';
import { DOCS_URL } from "../utils/constants";

class Docs extends PureComponent {
	render() {
		return (
			<Iframe url={DOCS_URL}
				position="absolute"
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        allowFullScreen
			/>
		)
	}
}

export default Docs;
