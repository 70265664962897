import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import sortBy from 'sort-by';

import PreviewMap from './PreviewMap';
import './TellersPage.css';

// Custom components
import AppHeader from './palmpay/AppHeader';
import EnhancedTable from './EnhancedTable';
import Footer from './palmpay/Footer';
import LayerMap from './LayerMap';
import { addProtocol, getProtocol, stripProtocol } from '../utils/url';

import { WHICH_OPTIONS } from '../utils/constants';

// Helpers
import Client from '../utils/feathers';
import Countries from 'country-list';

// Images
import LoadingGif from '../assets/loading_icon.gif';
import TellerPin from '../assets/teller_pin.png';

// List of countries
const countries = Countries();

const centerStyle = {
  marginTop: 20,
  marginBottom: 20
};

const loadingStyle = {
  textAlign: 'center',
  marginTop: 20,
  marginBottom: 20,
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto'
};

const mapsStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    minWidth              : '300px'
  }
};

const columnData = [
  { id: 'gt_name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
  { id: 'telegram', numeric: false, disablePadding: false, label: 'Telegram' },
  { id: 'keybase', numeric: false, disablePadding: false, label: 'Keybase' },
  { id: 'whatsapp', numeric: false, disablePadding: false, label: 'Whatsapp' },
  { id: 'viber', numeric: false, disablePadding: false, label: 'Viber' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
  { id: 'link', numeric: false, disablePadding: false, label: 'URL' },
  { id: 'map', numeric: false, disablePadding: false, label: 'Maps', disableSearch: true},
];

/**
 * Tellers page component.
 */
class TellersPage extends Component {
  constructor(props, context) {
    super(props, context);

    /** @type {ComponentState} */
    this.state = {
      tellers: {
        total: 0,
        limit: 0,
        skip: 0,
        data: []
      },
      tellersSearch: [],
      ambassadorsMarkers: [],
      merchantsMarkers: [],
      loading: false,
    };
  }

  /**
   * @description Lifecycle event handler called just after the App loads into the DOM.
   */
  UNSAFE_componentWillMount() {
    // Get the tellers list
    this.getTellers();
  }

  /**
   * @description Get tellers from the web service
   * @param {number} [limit=10] - Max items to be returned.
   * @param {number} [skip=0] - Start index search
   */
  getTellers = async (limit = 50, skip = 0) => {
    const app = this;
    // Initially we don't know how much the total value is, so to make sure we enter the loop
    // at least once we're just setting it to be 1
    let total = 1;

    const tellers = Client.service('api/v2/tellers');
    this.setState({loading: true});
    let result;
    while(skip < total){
      let partialResponse = await tellers.find({
        query: {
          //$sort: { account: 1 },
          $limit: limit,
          $skip: skip
        }
      });
      total = partialResponse.total;
      result === undefined ? result = partialResponse : partialResponse.data.map(this.fillResults(result));
      skip = skip + limit;
    }

    result.data.forEach(function(tellers){
      if(tellers.city !== undefined) tellers.city = (tellers.city).replace(/(^|\s)\S/g, l => l.toUpperCase());
      if(tellers.country !== undefined) tellers.country = countries.getName(tellers.country);
    });

    result.data.map(teller => {
      const infoDescription = <div>
        <div><b>Address</b>: {teller.address}</div>
        {(teller.phone) && (<div><b>Phone</b>: {teller.phone}</div>)}
        </div>;
      if(teller.telegram){
        teller.telegram_original = teller.telegram;
        teller.telegram = {
          searchText: teller.telegram_original,
          value: (
            <a
              href={`https://t.me/${(teller.telegram_original.trim().charAt(0) === '@') ?
                teller.telegram_original.trim().slice(1): teller.telegram_original.trim()}`}
              target="_blank"
              rel="noopener noreferrer"
            >{teller.telegram}</a>
          )
        };
      }

      teller.link = {
        searchText: stripProtocol(teller.url),
        value: (
          <a target="_blank" rel="noopener noreferrer"
          href={addProtocol(teller.url, getProtocol(teller.url))}>{stripProtocol(teller.url)}</a>
        )
      };
      teller.location = {
        searchText: `${teller.country} - ${teller.city}`,
        value: (teller.city) ? `${teller.city} - ${teller.country}`: teller.country
      }
      teller.map = <Button
        className="App-button"
        variant="contained"
        style={{
            backgroundColor: "#fdcf09",
            color: 'white',
            whiteSpace: 'nowrap'
        }}
        onClick={() => this.openMaps(teller.gt_name, app.getTellerMarker(teller))}
      >Show on Map
      </Button>;
      return teller;
    });

    // Once both return, update the state
    app.setState({
      loading: false,
      tellers: result,
      tellersSearch: result.data
    });
  };

  /**
   * @description Close Maps modal.
   */
  closeMapsModal() {
     this.setState({
       mapsLat: 0,
       mapsLon: 0,
       mapsModalIsOpen: false
     });
  }

  openMaps(name, marker){
    this.setState({
      mapsTitle: name,
      mapsDescription: marker.infoDescription,
      mapsLat: marker.lat,
      mapsLon: marker.lng,
      mapsModalIsOpen: true
    });
  }

  getTellerMarker = (teller) => {
    const which = WHICH_OPTIONS.filter(w => w.id.toLowerCase() === teller.which.toLowerCase());
      teller.which_value = ( teller.which && which.length > 0)  ?
        which[0].value :
        '';
    const infoDescription = (
      <div>
        <div><b>Address</b>: {teller.address}</div>
        {(teller.which) && (<div><b>Which:</b>: {teller.which}</div>)}
        {(teller.bitshares_address) && (<div><b>BTS Account:</b>: {teller.bitshares_address}</div>)}
        {(teller.address) && (<div><b>Address:</b>: {teller.address}</div>)}
        {(teller.telegram_original) && (<div><b>Telegram</b>:
          <a
            href={`https://t.me/${(teller.telegram_original.trim().charAt(0) === '@') ? teller.telegram_original.trim().slice(1): teller.telegram_original.trim()}`}
            target="_blank"
            rel="noopener noreferrer"
          >{teller.telegram_original}</a>
          </div>)
        }
        {(teller.keybase) && (<div><b>Keybase</b>: {teller.keybase}</div>)}
        {(teller.whatsapp) && (<div><b>Whatsapp</b>: {teller.whatsapp}</div>)}
        {(teller.viber) && (<div><b>Viber</b>: {teller.viber}</div>)}
        {(teller.email) && (<div><b>Email</b>: {teller.email}</div>)}
        {(teller.phone) && (<div><b>Phone</b>: {teller.phone}</div>)}
        {(teller.url) && (<div><b>URL:</b>: <a target="_blank" rel="noopener noreferrer"
          href={addProtocol(teller.url, getProtocol(teller.url))}>{stripProtocol(teller.url)}</a></div>)}
      </div>
    );
    const marker = {
      lat: teller.lat,
      lng: teller.lon,
      withInfo: true,
      infoTitle: teller.gt_name,
      infoDescription: infoDescription,
    };
    return marker;
  };

  handleSearchChange(data){
    this.setState({ tellersSearch: data });
  }


  render() {
    let { data: tellersData } = this.state.tellers;

    const tellersMarkers = this.state.tellersSearch.map(teller => {
      return this.getTellerMarker(teller);
    });

    tellersData = tellersData.sort(sortBy('gt_name'));

    const textComponent = (
      <span>
        <FormattedHTMLMessage id="tellers.description" />
      </span>
    );

    return (
      <div>
        <AppHeader />
        <section data-spy="scroll" data-target="#mainNav" id="services" className="tellers_services">
          <div className="containerfix">
            <div className="row">
              <div className="col-md-10 mx-md-auto">
                <h2 className="ambassadorsTitle merchantsMargin" style={centerStyle}><FormattedHTMLMessage id="tellers.title" /></h2>
                { /* Conditional Rendering */}
                {(this.state.loading) ? (
                  <img src={LoadingGif} alt="Loading" style={loadingStyle} />
                ): (
                  <div>

                    <Modal
                      isOpen={this.state.mapsModalIsOpen}
                      onRequestClose={() => this.closeMapsModal()}
                      style={mapsStyles}
                      ariaHideApp={false}
                      contentLabel={this.state.mapsTitle}
                    >
                      <PreviewMap
                        icon={TellerPin}
                        infoTitle={this.state.mapsTitle}
                        infoDescription={this.state.mapsDescription}
                        lat={this.state.mapsLat}
                        lng={this.state.mapsLon}
                        width="800px"
                        height="600px"
                      />
                    </Modal>

                    <div>
                      <br />
                      <EnhancedTable
                        description={textComponent}
                        columnData={columnData}
                        data={tellersData}
                        orderBy="gt_name"
                        rowsPerPage={10}
                        showSearchColumns={false}
                        isAdmin={false}
                        onSearchChange={(tellersData) => this.handleSearchChange(tellersData)}
                      />
                    </div>

                    <div className="map">
                      <LayerMap
                        ambassadorsLayer={false}
                        merchantsLayer={false}
                        tellersLayer={true}
                        tellers={tellersMarkers}
                        mapHeight={'600px'}
                        showControls={this.state.mapsModalIsOpen}
                      />
                    </div>
                  </div>
                )}
                </div>
              </div>
            </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default TellersPage;
