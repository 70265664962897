import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import MorphItLightLogo from '../assets/images/logo_light.png';
import MorphItLogo from '../assets/images/logo.png';
import sr from '../utils/ScrollReveal';

class Menu extends Component {

  componentDidMount() {
    const config = {
      origin: 'right',
      duration: 1000,
      delay: 150,
      distance: '500px',
      scale: 1,
      easing: 'ease',
    };

    sr.reveal(this.refs.box1, config)
  }

	render() {
		return (
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
        <Link className='navbar-brand js-scroll-trigger' to='/'>
          <img className='logo-light' src={MorphItLightLogo} alt='logo' />
          <img className='logo-dark' ref='box1' src={MorphItLogo} alt='logo' />
        </Link>
        <button className='navbar-toggler navbar-toggler-right' type='button' data-toggle='collapse'
                data-target='#navbarResponsive' aria-controls='navbarResponsive' aria-expanded='false'
                aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon' />
        </button>
        <div className='collapse navbar-collapse' id='navbarResponsive'>
          <ul className='navbar-nav ml-auto'>
            <li className='nav-item dropdown'>
              <Link className='nav-link dropdown-toggle' data-toggle='dropdown' to='/support'>Support<b className='caret'></b></Link>
              <ul className='dropdown-menu'>
                <li>
                  <Link to='/faq'><i className='fa fa-question' aria-hidden='true' /> FAQ</Link>
                </li>
                <li>
                  <Link to='/contact'><i className='fa fa-envelope' aria-hidden='true' /> Contact</Link>
                </li>
                <li>
                  <Link title='dtube' target='_blank' to='https://d.tube/c/Linkgorise'><i className='fa fa-play' aria-hidden='true' /> DTube</Link>
                </li>
                <li>
                  <Link title='Telegram' to='https://t.me/Linkgorise' target='_blank'><i className='fa fa-paper-plane' /> Telegram</Link>
                </li>
                <li>
                  <Link title='Twitter' target='_blank' to='https://twitter.com/Linkgorise_world'><i className='fa fa-twitter' /> Twitter</Link>
                </li>
              </ul>
            </li>
            <li className='nav-item dropdown'>
              <Link className='nav-link profile-btn' data-toggle='dropdown' to='#'>
              <canvas width='25' height='25' data-jdenticon-value='user127' />
            </Link>
              <ul className='dropdown-menu'>
                <li><Link to='/profile'>Profile</Link></li>
                <li><Link to='/surety-funding'>Surety Funding</Link></li>
                <li><Link to='/settings'>Settings</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
		)
	}
}

export default Menu;