import React, { Component } from 'react';
import Iframe from 'react-iframe';
import '../HomePage.css';

class HomePageTemp extends Component {

  render() {
    return (
      <Iframe url="/temp/index.html"
        position="absolute"
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        allowFullScreen
      />
    )
  }
}

export default HomePageTemp;
