import React, { Component } from 'react';
import './FaqPage.css';

class FaqPage extends Component {

  render() {
    return (
      <div>
        <div class="intro-content" />
        <div class="container">
          <section id="mp_faq">
            <h1>Frequently Asked Questions</h1>
            <div class="accordion">
              <div class="accordion-item"> <a>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</a>
                <div class="content">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.</p>
                </div>
              </div>
              <div class="accordion-item"> <a>Lorem ipsum dolor sit amet?</a>
                <div class="content">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.</p>
                </div>
              </div>
              <div class="accordion-item"> <a>Lorem ipsum dolor sit amet?</a>
                <div class="content">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.</p>
                </div>
              </div>
              <div class="accordion-item"> <a>Lorem ipsum dolor sit?</a>
                <div class="content">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.</p>
                </div>
              </div>
              <div class="accordion-item"> <a>Lorem ipsum dolor sit amet?</a>
                <div class="content">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default FaqPage;