import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import HomePage from './components/temp/HomePageTemp';
import Faq from './components/FaqPage';
import Footer from './components/Footer';
import Menu from './components/Menu';
import Docs from './components/Docs';
import TellersPage from './components/TellersPage';

class App extends Component {

  render() {
    return (
      <div className="app">
        <Route exact path='/' render={() => (
          <HomePage />
        )}/>
        <Route exact path='/faq' render={({history}) => (
          <div>
            <Menu />
            <Faq />
            <Footer />
          </div>
        )}/>
        <Route exact path='/tellers' render={({history}) => (
          <TellersPage />
        )}/>
        <Route exact path='/docs' render={({history}) => (
          <Docs />
        )}/>
      </div>
    );
  }
}

export default App;
