import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { addLocaleData, IntlProvider } from "react-intl";
import en from 'react-intl/locale-data/en';

import App from './App';
import './index.css';

// Import all css needed for the app at once
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./assets/palmpay/palmpay.css";
import "./index.css";

import messages_en from "./translations/en.json";

addLocaleData([...en]);

const messages = {
    'en': messages_en,
};

const language = 'en';

// Add IntlProvider to make the internationalization functions visible in all
// our components.
ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </IntlProvider>,
  document.getElementById("root")
);
