const GOOGLE_MAPS_API = 'AIzaSyAwVFmgFPt_0fji8qFH5ecGG1A_ZviHe7M';
const DOCS_URL = 'https://bridge.computationalcore.com/explorer/';
const API_URL = 'https://bridge.computationalcore.com';
const WHICH_OPTIONS = [
  {
    'id': 'G',
    'value': 'Gateway',
  },
  {
    'id': 'T',
    'value': 'Teller'
  }
];

export {
  API_URL,
  DOCS_URL,
  GOOGLE_MAPS_API,
  WHICH_OPTIONS
};
