import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './Footer.css';

import OnionLogo from '../assets/images/onion-logo.png';
import IpfsLogo from '../assets/images/ipfs-logo-white.png';
import GooglePlayLogo from '../assets/images/google-play.png';
import MorphItLogo from '../assets/images/logo.png';

class Footer extends Component {

  componentDidMount() {
    $(".custom-select").each(function() {
      let classes = $(this).attr("class");
      let template =  '<div class="' + classes + '">';
      template += '<span class="custom-select-trigger">' + $(this).attr("placeholder") + '</span>';
      template += '<div class="custom-options">';
      $(this).find("option").each(function() {
        template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
      });
      template += '</div></div>';

      $(this).wrap('<div class="custom-select-wrapper"></div>');
      $(this).hide();
      $(this).after(template);
    });
    $(".custom-option:first-of-type").hover(function() {
      $(this).parents(".custom-options").addClass("option-hover");
    }, function() {
      $(this).parents(".custom-options").removeClass("option-hover");
    });
    $(".custom-select-trigger").on("click", function() {
      $('html').one('click',function() {
        $(".custom-select").removeClass("opened");
      });
      $(this).parents(".custom-select").toggleClass("opened");
      // event.stopPropagation();
    });
    $(".custom-option").on("click", function() {
      $(this).parents(".custom-select-wrapper").find("select").val($(this).data("value"));
      $(this).parents(".custom-options").find(".custom-option").removeClass("selection");
      $(this).addClass("selection");
      $(this).parents(".custom-select").removeClass("opened");
      $(this).parents(".custom-select").find(".custom-select-trigger").text($(this).text());
    });
  }

  render() {
    return (
      <footer className='clearfix'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='logo-footer' style={{marginTop: 40}}>
                <img src={MorphItLogo} alt='logo' />
                <p>© <span id='year' /> <Link target='_blank' to='http://www.agorise.world/' className='ng-binding'>Agorise, Ltd.</Link><br/>
                </p>
                <a className='btn-terms' href='#'>Terms of Use</a>

              </div>
            </div>
            <div className='col-md-3'>
              <div style={{marginTop: 10}}>
                <ul className='list-unstyled'>
                  <li><Link to='/about' className='ng-binding'>About</Link></li>
                  <li><Link to='/fees' className='ng-binding'>Fees</Link></li>
                  <li><div>
                    <a>
                      Affiliates
                    </a>
                    <ul id='list-affiliates'>
                      <li>
                        <a target='_blank' className='btn-floating'>
                          <img src={OnionLogo} alt='Onion' /> Onion site
                        </a>
                      </li>
                      <li>
                        <a target='_blank' className='btn-floating'>
                          <img src={IpfsLogo} alt='IPFS' /> IPFS site
                        </a>
                      </li>
                      <li>
                        <a target='_blank' className='btn-floating'>
                          <img src={GooglePlayLogo} alt='The App' /> The App
                        </a>
                      </li>
                    </ul>
                  </div></li>
                </ul>
              </div>
            </div>
            <div className='col-md-3'>
              <div>
                <div className='social-network'>
                  <Link target='_blank' to='https://t.me/Agorise' className='icon telegram' title='Telegram' />
                  <Link target='_blank' to='https://twitter.com/Agorise_world' className='icon twitter' title='Twitter' />
                  <Link target='_blank' to='https://youtu.be/Agorise' className='icon youtube' title='Youtube' />
                  <Link target='_blank' to='https://d.tube/c/agorise' className='icon dtube' title='DTube' />
                  <Link target='_blank' to='https://steemit.com/@Agorise' className='icon steemit' title='Steemit' />
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div>
                <div style={{marginTop: 64}} className='col-md-3 col-sm-12 footer-box fadeInUp animated'>
                  <select name='sources' id='sources' className='custom-select sources' placeholder='English'>
                    <option value='english'>English</option>
                    <option value='russian'>pусский</option>
                    <option value='spanish'>Español</option>
                    <option value='chinese'>中文</option>
                    <option value='portuguese'>Português</option>
                    <option value='greek'>Ελληνικά</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
